import { createRouter, createWebHistory } from "vue-router";
import ClientsRoute from "@/routes/Clients";
// import TenantRoute from "@/routes/Tenant";
import OtaManagement from "@/views/OtaManagement.vue";
import TenantManagement from "@/views/TenantManagement.vue";  // Update the import

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        { path: "/", redirect: "/clients" },
        {
            path: "/clients",
            name: "Clients",
            component: ClientsRoute
        },
        {
            path: "/tenants",
            name: "Tenants",
            component: TenantManagement
        },
        // {
        //     path: "/tenants/:tenant",
        //     name: "Tenant",
        //     component: TenantRoute
        // },
        {
            path: "/ota",
            name: "Ota",
            component: OtaManagement,
        }
    ],
});

export default router;