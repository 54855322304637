import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { useAuthStore } from './store/auth';

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import "@mdi/font/css/materialdesignicons.css";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import JsonCSV from 'vue-json-csv'

const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp(App);
const pinia = createPinia();

app.use(pinia)
  .use(router)
  .use(vuetify)
  .component('VueDatePicker', VueDatePicker)
  .component('downloadCsv', JsonCSV);

// Initialize Firebase auth before mounting the app
const authStore = useAuthStore();
authStore.init().then(() => {
  app.mount('#app');
});