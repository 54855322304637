<template>
  <v-app>
    <v-app-bar color="blue darken-3" dark app clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="authStore.isAuthenticated"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span>SafeTelecom Admin</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- Profile Menu Popup -->
      <v-menu v-model="profileMenuOpen" :close-on-content-click="false" location="bottom end" min-width="200">
        <template v-slot:activator="{ props }">
          <v-btn v-if="authStore.isAuthenticated" v-bind="props" icon>
            <v-avatar :color="generateAvatarColor()" size="32" class="text-white">
              {{ getInitials() }}
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <ProfileMenu @close="profileMenuOpen = false" />
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" v-if="authStore.isAuthenticated">
      <!-- Current user profile section -->
      <div class="pa-4">
        <v-avatar :color="generateAvatarColor()" size="64" class="text-white mb-2">
          {{ getInitials() }}
        </v-avatar>
        <div class="text-h6">{{ displayName }}</div>
        <div class="text-subtitle-2">{{ authStore.user?.email }}</div>
      </div>

      <v-divider></v-divider>

      <v-list>
        <v-list-subheader>Navigation</v-list-subheader>
        <v-list-item v-for="item in items" :key="item.text" :to="item.path" :prepend-icon="item.icon">
          {{ item.text }}
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useAuthStore } from '@/store/auth';
import ProfileMenu from '@/components/ProfileMenu.vue';

const authStore = useAuthStore();
const drawer = ref(null);
const profileMenuOpen = ref(false);

const items = [
  { icon: 'mdi-account-multiple', text: 'Clients', path: '/clients' },
  { icon: 'mdi-store', text: 'Tenants', path: '/tenants' },
  { icon: 'mdi-cellphone-arrow-down', text: 'OTA Updates', path: '/ota' }, // Updated icon
];

const displayName = computed(() => {
  return authStore.user?.displayName || authStore.user?.email?.split('@')[0] || 'User';
});

const getInitials = () => {
  const name = displayName.value;
  return name
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);
};

const generateAvatarColor = () => {
  const colors = [
    'indigo-darken-2',
    'blue-darken-2',
    'teal-darken-2',
    'green-darken-2',
    'deep-purple-darken-2'
  ];

  const email = authStore.user?.email || '';
  const sum = email.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return colors[sum % colors.length];
};
</script>