<template>
    <v-container>
        <!-- Header with Create button -->
        <v-row>
            <v-col cols="12" class="d-flex align-center">
                <h1>Tenant Management</h1>
                <v-spacer></v-spacer>
                <v-btn color="primary" prepend-icon="mdi-plus" @click="openCreateDialog">
                    New Tenant
                </v-btn>
            </v-col>
        </v-row>

        <!-- Filters -->
        <v-row>
            <v-col cols="12" md="4">
                <v-text-field v-model="filters.search" label="Search" prepend-inner-icon="mdi-magnify" clearable
                    hide-details class="mb-4"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-select v-model="filters.products" :items="['dialer', 'ota']" label="Product" clearable hide-details
                    class="mb-4" multiple chips></v-select>
            </v-col>
            <v-col cols="12" md="4">
                <v-select v-model="filters.premium" :items="[
                    { title: 'All', value: null },
                    { title: 'Premium', value: true },
                    { title: 'Basic', value: false }
                ]" label="UI Type" hide-details class="mb-4"></v-select>
            </v-col>
        </v-row>

        <!-- Data Table -->
        <v-row>
            <v-col cols="12">
                <v-data-table v-model="selected" :headers="headers" :items="filteredTenants" :loading="loading"
                    show-select class="elevation-1" :sort-by="[{ key: 'name', order: 'asc' }]">
                    <template #[`item.isPremiumUI`]="{ item }">
                        <v-switch v-model="item.isPremiumUI" color="success" hide-details @change="updateTenant(item)"
                            density="compact"></v-switch>
                    </template>

                    <template #[`item.isABUpdates`]="{ item }">
                        <v-switch v-model="item.isABUpdates" color="success" hide-details @change="updateTenant(item)"
                            density="compact"></v-switch>
                    </template>

                    <template #[`item.isForcedUpdates`]="{ item }">
                        <v-switch v-model="item.isForcedUpdates" color="success" hide-details
                            @change="updateTenant(item)" density="compact"></v-switch>
                    </template>

                    <template #[`item.products`]="{ item }">
                        <v-chip-group>
                            <v-chip v-for="product in item.products" :key="product" size="small" color="primary">
                                {{ product }}
                            </v-chip>
                        </v-chip-group>
                    </template>

                    <template #[`item.actions`]="{ item }">
                        <v-btn icon variant="text" color="primary" size="small" class="mr-2" @click="editItem(item)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon variant="text" color="error" size="small" @click="confirmDelete([item])">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </template>

                    <template #top>
                        <v-toolbar flat>
                            <v-toolbar-title>Tenants</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-btn color="error" :disabled="!selected.length" @click="confirmDelete(selected)"
                                prepend-icon="mdi-delete">
                                Delete Selected
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <!-- Create/Edit Dialog -->
        <v-dialog v-model="dialog" max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ isEditing ? 'Edit Tenant' : 'New Tenant' }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="editedItem.name" label="Name"
                                    :rules="[v => !!v || 'Name is required']"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="editedItem.uid" label="UID"
                                    :rules="[v => !!v || 'UID is required']" hint="Format: 29091FDH200BX2"
                                    persistent-hint></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select v-model="editedItem.products" :items="['dialer', 'ota']" label="Products"
                                    multiple chips
                                    :rules="[v => v.length > 0 || 'At least one product is required']"></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-switch v-model="editedItem.isPremiumUI" label="Premium UI"
                                    color="success"></v-switch>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-switch v-model="editedItem.isABUpdates" label="AB Updates"
                                    color="success"></v-switch>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-switch v-model="editedItem.isForcedUpdates" label="Forced Updates"
                                    color="success"></v-switch>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-switch v-model="editedItem.hasChangelog" label="Has Changelog"
                                    color="success"></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-darken-1" variant="text" @click="closeDialog">
                        Cancel
                    </v-btn>
                    <v-btn color="blue-darken-1" variant="text" @click="saveTenant" :loading="saving"
                        :disabled="!isValid">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Delete Confirmation Dialog -->
        <v-dialog v-model="deleteDialog" max-width="500px">
            <v-card>
                <v-card-title>Delete Tenants</v-card-title>
                <v-card-text>
                    Are you sure you want to delete {{ itemsToDelete.length }} tenant(s)? This action cannot be undone.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-darken-1" variant="text" @click="closeDelete">
                        Cancel
                    </v-btn>
                    <v-btn color="error" variant="text" @click="deleteItemsConfirm" :loading="deleting">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

defineOptions({
    name: 'TenantManagement'
});

// State
const tenants = ref([]);
const loading = ref(true);
const selected = ref([]);
const deleteDialog = ref(false);
const itemsToDelete = ref([]);
const deleting = ref(false);
const dialog = ref(false);
const isEditing = ref(false);
const saving = ref(false);

// Initialize filters
const filters = ref({
    search: '',
    products: [],
    premium: null
});

const defaultItem = {
    name: '',
    uid: '',
    isPremiumUI: false,
    hasChangelog: true,
    isABUpdates: false,
    isForcedUpdates: false,
    products: ['ota']
};

const editedItem = ref({ ...defaultItem });

// Table headers
const headers = [
    {
        title: 'Name',
        align: 'start',
        sortable: true,
        key: 'name',
    },
    {
        title: 'UID',
        align: 'start',
        sortable: true,
        key: 'uid',
    },
    {
        title: 'Premium UI',
        align: 'center',
        sortable: true,
        key: 'isPremiumUI',
    },
    {
        title: 'AB Updates',
        align: 'center',
        sortable: true,
        key: 'isABUpdates',
    },
    {
        title: 'Forced Updates',
        align: 'center',
        sortable: true,
        key: 'isForcedUpdates',
    },
    {
        title: 'Products',
        align: 'start',
        sortable: false,
        key: 'products',
    },
    {
        title: 'Actions',
        align: 'center',
        sortable: false,
        key: 'actions',
    },
];

// Computed properties
const filteredTenants = computed(() => {
    return tenants.value.filter(item => {
        const searchMatch = !filters.value.search ||
            item.name.toLowerCase().includes(filters.value.search.toLowerCase()) ||
            item.uid.toLowerCase().includes(filters.value.search.toLowerCase());

        const productsMatch = !filters.value.products.length ||
            filters.value.products.every(p => item.products.includes(p));

        const premiumMatch = filters.value.premium === null ||
            item.isPremiumUI === filters.value.premium;

        return searchMatch && productsMatch && premiumMatch;
    });
});

const isValid = computed(() => {
    return editedItem.value.name &&
        editedItem.value.uid &&
        editedItem.value.products.length > 0;
});

// Methods
const fetchTenants = async () => {
    loading.value = true;
    try {
        const response = await axios.get('https://whitelabel.safetelecom.net/api/tenants');
        tenants.value = response.data;
    } catch (error) {
        console.error('Failed to fetch tenants:', error);
    } finally {
        loading.value = false;
    }
};

const editItem = (item) => {
    if (!item || !item._id) {
        console.error('Invalid item for editing:', item);
        return;
    }

    editedItem.value = { ...item };
    isEditing.value = true;
    dialog.value = true;
};

const openCreateDialog = () => {
    editedItem.value = { ...defaultItem };
    isEditing.value = false;
    dialog.value = true;
};

const closeDialog = () => {
    dialog.value = false;
    editedItem.value = { ...defaultItem };
};

const updateTenant = async (item) => {
    if (!item || !item._id) {
        console.error('Invalid item or missing ID:', item);
        return;
    }

    try {
        const response = await axios.put(
            `https://whitelabel.safetelecom.net/api/tenants/${item._id}`,
            item
        );

        // Update local data with response
        const index = tenants.value.findIndex(t => t._id === item._id);
        if (index !== -1) {
            tenants.value[index] = response.data;
        }
    } catch (error) {
        console.error('Failed to update tenant:', error);
        // Revert the switch if the update failed
        if (error.response?.data?.error) {
            // You might want to show this error to the user
            console.error('Server error:', error.response.data.error);
        }
    }
};

const saveTenant = async () => {
    if (!editedItem.value) {
        console.error('No item to save');
        return;
    }

    saving.value = true;
    try {
        let response;
        if (isEditing.value && editedItem.value._id) {
            response = await axios.put(
                `https://whitelabel.safetelecom.net/api/tenants/${editedItem.value._id}`,
                editedItem.value
            );
        } else {
            response = await axios.post(
                'https://whitelabel.safetelecom.net/api/tenants',
                editedItem.value
            );
        }

        if (response.data) {
            if (isEditing.value) {
                const index = tenants.value.findIndex(item => item._id === editedItem.value._id);
                if (index !== -1) {
                    tenants.value[index] = response.data;
                }
            } else {
                tenants.value.push(response.data);
            }
        }

        closeDialog();
    } catch (error) {
        console.error('Save error:', error);
    } finally {
        saving.value = false;
    }
};

const confirmDelete = (items) => {
    itemsToDelete.value = items;
    deleteDialog.value = true;
};

const closeDelete = () => {
    deleteDialog.value = false;
    itemsToDelete.value = [];
};

const deleteItemsConfirm = async () => {
    deleting.value = true;
    try {
        await Promise.all(
            itemsToDelete.value.map(item =>
                axios.delete(`https://whitelabel.safetelecom.net/api/tenants/${item._id}`)
            )
        );
        // Remove deleted items from the selection and data
        selected.value = selected.value.filter(
            item => !itemsToDelete.value.includes(item)
        );
        tenants.value = tenants.value.filter(
            item => !itemsToDelete.value.some(deleteItem => deleteItem._id === item._id)
        );
        closeDelete();
    } catch (error) {
        console.error('Failed to delete items:', error);
    } finally {
        deleting.value = false;
    }
};

// Lifecycle
onMounted(() => {
    fetchTenants();
});
</script>

<style scoped>
.v-data-table {
    width: 100%;
}

.v-chip-group {
    margin: 0;
}

/* Dialog transitions */
.v-dialog-transition-enter-active,
.v-dialog-transition-leave-active {
    transition: all 0.3s ease-in-out;
}

.v-dialog-transition-enter-from,
.v-dialog-transition-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}

/* Table transitions */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>