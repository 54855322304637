import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDBDwQFODU70nmrsKvY2UZUG-LyoN-1HLU",
    authDomain: "safetelecom-whitelabel.firebaseapp.com",
    projectId: "safetelecom-whitelabel",
    storageBucket: "safetelecom-whitelabel.firebasestorage.app",
    messagingSenderId: "708307870538",
    appId: "1:708307870538:web:f82f97b013db0f3bdb17b3"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);