// src/store/auth.js
import { defineStore } from 'pinia';
import {
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged
} from 'firebase/auth';
import { auth } from '@/firebase';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: null,
        loading: false,
        error: null
    }),

    getters: {
        isAuthenticated: (state) => !!state.user,
    },

    actions: {
        init() {
            return new Promise((resolve) => {
                this.loading = true;
                onAuthStateChanged(auth, (user) => {
                    this.user = user;
                    this.loading = false;
                    resolve(user);
                });
            });
        },

        async login(email, password) {
            this.loading = true;
            this.error = null;
            try {
                const userCredential = await signInWithEmailAndPassword(auth, email, password);
                this.user = userCredential.user;
                return true;
            } catch (error) {
                this.error = error.message;
                return false;
            } finally {
                this.loading = false;
            }
        },

        async logout() {
            try {
                await signOut(auth);
                this.user = null;
                return true;
            } catch (error) {
                this.error = error.message;
                return false;
            }
        }
    }
});