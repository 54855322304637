<template>
    <div class="profile-menu">
        <v-list>
            <v-list-subheader>Profile</v-list-subheader>
            <v-list-item>
                <template v-slot:prepend>
                    <v-avatar :color="generateAvatarColor()" class="text-white">
                        {{ getInitials() }}
                    </v-avatar>
                </template>

                <v-list-item-content>
                    <v-list-item-title>{{ displayName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ authStore.user?.email }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2"></v-divider>

            <v-list-item @click="handleLogout" :disabled="isLoggingOut">
                <template v-slot:prepend>
                    <v-icon>mdi-logout</v-icon>
                </template>
                <v-list-item-title>
                    {{ isLoggingOut ? 'Logging out...' : 'Logout' }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/auth';

const router = useRouter();
const authStore = useAuthStore();
const isLoggingOut = ref(false);

// Get display name from email if no display name is set
const displayName = computed(() => {
    return authStore.user?.displayName || authStore.user?.email?.split('@')[0] || 'User';
});

// Generate initials from display name
const getInitials = () => {
    const name = displayName.value;
    return name
        .split(' ')
        .map(word => word[0])
        .join('')
        .toUpperCase()
        .slice(0, 2);
};

// Generate a consistent color based on the user's email
const generateAvatarColor = () => {
    const colors = [
        'indigo-darken-2',
        'blue-darken-2',
        'teal-darken-2',
        'green-darken-2',
        'deep-purple-darken-2'
    ];

    const email = authStore.user?.email || '';
    const sum = email.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return colors[sum % colors.length];
};

const handleLogout = async () => {
    isLoggingOut.value = true;
    try {
        await authStore.logout();
        router.push('/login');
    } finally {
        isLoggingOut.value = false;
    }
};
</script>