<template>
    <v-container>
        <!-- Header with Create button -->
        <v-row>
            <v-col cols="12" class="d-flex align-center">
                <h1>OTA Management</h1>
                <v-spacer></v-spacer>
                <v-btn color="primary" prepend-icon="mdi-plus" @click="openCreateDialog">
                    New OTA
                </v-btn>
            </v-col>
        </v-row>

        <!-- Filters -->
        <v-row>
            <v-col cols="12" md="3">
                <v-text-field v-model="filters.search" label="Search" prepend-inner-icon="mdi-magnify" clearable
                    hide-details class="mb-4"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-select v-model="filters.tenant" :items="uniqueTenants" label="Tenant" clearable hide-details
                    class="mb-4"></v-select>
            </v-col>
            <v-col cols="12" md="3">
                <v-select v-model="filters.device" :items="uniqueDevices" label="Device" clearable hide-details
                    class="mb-4"></v-select>
            </v-col>
            <v-col cols="12" md="3">
                <v-select v-model="filters.published" :items="[
                    { title: 'All', value: null },
                    { title: 'Published', value: true },
                    { title: 'Unpublished', value: false }
                ]" label="Status" hide-details class="mb-4"></v-select>
            </v-col>
        </v-row>

        <!-- Data Table -->
        <v-row>
            <v-col cols="12">
                <v-data-table v-model="selected" :headers="headers" :items="filteredUpdates" :loading="loading"
                    show-select class="elevation-1">
                    <template #[`item.published`]="{ item }">
                        <v-switch v-model="item.published" color="success" hide-details @change="updateOta(item)"
                            density="compact"></v-switch>
                    </template>

                    <template #[`item.datetime`]="{ item }">
                        {{ new Date(item.datetime * 1000).toLocaleString() }}
                    </template>

                    <template #[`item.size`]="{ item }">
                        {{ formatFileSize(item.size) }}
                    </template>

                    <template #[`item.actions`]="{ item }">
                        <v-btn icon variant="text" color="primary" size="small" class="mr-2" @click="editItem(item)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon variant="text" color="error" size="small" @click="confirmDelete([item])">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </template>

                    <template #top>
                        <v-toolbar flat>
                            <v-toolbar-title>OTA Updates</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-btn color="error" :disabled="!selected.length" @click="confirmDelete(selected)"
                                prepend-icon="mdi-delete">
                                Delete Selected
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <!-- Create/Edit Dialog -->
        <v-dialog v-model="dialog" max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ isEditing ? 'Edit OTA' : 'New OTA' }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <!-- URL input for new OTA only -->
                        <v-row v-if="!isEditing">
                            <v-col cols="12">
                                <v-text-field v-model="gcsUrl" label="GCS URL" :error-messages="urlError"
                                    :rules="[v => !!v || 'URL is required']"
                                    hint="Example: https://storage.googleapis.com/kosheros-store/ota-updates/redfin-litaher-14.0.0-RELEASE-ota_update-2024.09.30.03-1727665638.zip"
                                    persistent-hint @input="parseGcsUrl"></v-text-field>
                            </v-col>
                        </v-row>

                        <!-- Display parsed info alert -->
                        <v-row v-if="!isEditing && editedItem.filename">
                            <v-col cols="12">
                                <v-alert color="info" variant="tonal" :title="editedItem.filename" class="mb-4">
                                    OTA information parsed successfully
                                </v-alert>
                            </v-col>
                        </v-row>

                        <!-- OTA Details Fields -->
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="editedItem.device" label="Device"
                                    :rules="[v => !!v || 'Device is required']"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="editedItem.tenant" label="Tenant"
                                    :rules="[v => !!v || 'Tenant is required']"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="editedItem.version" label="Version"
                                    :rules="[v => !!v || 'Version is required']"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="editedItem.romtype" label="ROM Type"
                                    :rules="[v => !!v || 'ROM Type is required']"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="editedItem.incrementalTimestamp" label="Incremental Timestamp"
                                    :rules="[v => !!v || 'Timestamp is required']"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="editedItem.datetime" label="Date/Time"
                                    :rules="[v => !!v || 'Date/Time is required']"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select v-model="editedItem.channel" :items="['retail']" label="Channel"
                                    :rules="[v => !!v || 'Channel is required']"></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-switch v-model="editedItem.published" label="Published" color="success"></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-darken-1" variant="text" @click="closeDialog">
                        Cancel
                    </v-btn>
                    <v-btn color="blue-darken-1" variant="text" @click="saveItem" :loading="saving"
                        :disabled="!isValid">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Delete Confirmation Dialog -->
        <v-dialog v-model="deleteDialog" max-width="500px">
            <v-card>
                <v-card-title>Delete OTA Updates</v-card-title>
                <v-card-text>
                    Are you sure you want to delete {{ itemsToDelete.length }} OTA update(s)? This action cannot be
                    undone.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-darken-1" variant="text" @click="closeDelete">
                        Cancel
                    </v-btn>
                    <v-btn color="error" variant="text" @click="deleteItemsConfirm" :loading="deleting">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

// State
const updates = ref([]);
const loading = ref(true);
const selected = ref([]);
const deleteDialog = ref(false);
const itemsToDelete = ref([]);
const deleting = ref(false);
const dialog = ref(false);
const isEditing = ref(false);
const saving = ref(false);
const gcsUrl = ref('');
const urlError = ref(null);

// Initialize filters with default values
const filters = ref({
    search: '',
    tenant: null,
    device: null,
    published: null
});

const defaultItem = {
    published: false,
    channel: 'retail',
    device: '',
    filename: '',
    romtype: '',
    url: '',
    version: '',
    size: 0,
    incrementalTimestamp: '',
    tenant: '',
    datetime: null,
};

const editedItem = ref({ ...defaultItem });

// Table headers
const headers = [
    {
        title: 'Device',
        align: 'start',
        sortable: true,
        key: 'device',
    },
    {
        title: 'Tenant',
        align: 'start',
        sortable: true,
        key: 'tenant',
    },
    {
        title: 'Version',
        align: 'start',
        sortable: true,
        key: 'version',
    },
    {
        title: 'Status',
        align: 'start',
        sortable: true,
        key: 'published',
    },
    {
        title: 'ROM Type',
        align: 'start',
        sortable: true,
        key: 'romtype',
    },
    {
        title: 'Date',
        align: 'start',
        sortable: true,
        key: 'datetime',
    },
    {
        title: 'Size',
        align: 'end',
        sortable: true,
        key: 'size',
    },
    {
        title: 'Actions',
        align: 'center',
        sortable: false,
        key: 'actions',
    },
];

// Computed properties
const uniqueTenants = computed(() => {
    return [...new Set(updates.value.map(item => item.tenant))].sort();
});

const uniqueDevices = computed(() => {
    return [...new Set(updates.value.map(item => item.device))].sort();
});

const filteredUpdates = computed(() => {
    return updates.value.filter(item => {
        const searchMatch = !filters.value.search ||
            Object.values(item).some(val =>
                String(val).toLowerCase().includes(filters.value.search.toLowerCase())
            );

        const tenantMatch = !filters.value.tenant ||
            item.tenant === filters.value.tenant;

        const deviceMatch = !filters.value.device ||
            item.device === filters.value.device;

        const publishedMatch = filters.value.published === null ||
            item.published === filters.value.published;

        return searchMatch && tenantMatch && deviceMatch && publishedMatch;
    });
});

const isValid = computed(() => {
    return editedItem.value.device &&
        editedItem.value.tenant &&
        editedItem.value.version &&
        editedItem.value.romtype &&
        editedItem.value.incrementalTimestamp &&
        editedItem.value.datetime;
});

// Methods
const fetchUpdates = async () => {
    loading.value = true;
    try {
        const response = await axios.get('https://ota.safetelecom.net/api/updates');
        updates.value = response.data;
    } catch (error) {
        console.error('Failed to fetch updates:', error);
    } finally {
        loading.value = false;
    }
};

const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const parseGcsUrl = () => {
    urlError.value = null;

    if (!gcsUrl.value) {
        return;
    }

    try {
        const url = new URL(gcsUrl.value);
        if (!url.pathname.startsWith('/kosheros-store/ota-updates/')) {
            urlError.value = 'Invalid GCS URL format';
            return;
        }

        const filename = url.pathname.split('/').pop();
        const parts = filename.split('-');

        if (parts.length < 7) {
            urlError.value = 'Invalid filename format in URL';
            return;
        }

        editedItem.value = {
            ...defaultItem,
            filename,
            device: parts[0] || '',
            tenant: parts[1] || '',
            version: parts[2] || '',
            romtype: parts[3] || '',
            incrementalTimestamp: parts[5] || '',
            datetime: parseInt(parts[6].split('.')[0], 10) || null,
            url: gcsUrl.value
        };
    } catch (err) {
        urlError.value = 'Invalid URL format';
        console.error('URL parse error:', err);
    }
};

const editItem = (item) => {
    if (!item || !item._id) {
        console.error('Invalid item for editing:', item);
        return;
    }

    editedItem.value = { ...item };
    isEditing.value = true;
    dialog.value = true;
};

const openCreateDialog = () => {
    editedItem.value = { ...defaultItem };
    gcsUrl.value = '';
    urlError.value = null;
    isEditing.value = false;
    dialog.value = true;
};

const closeDialog = () => {
    dialog.value = false;
    editedItem.value = { ...defaultItem };
    gcsUrl.value = '';
    urlError.value = null;
};

const updateOta = async (item) => {
    if (!item || !item._id) {
        console.error('Invalid item or missing ID:', item);
        return;
    }

    try {
        const response = await axios.put(
            `https://ota.safetelecom.net/api/updates/${item._id}`,
            item
        );

        // Update local data with response
        const index = updates.value.findIndex(u => u._id === item._id);
        if (index !== -1) {
            updates.value[index] = response.data;
        }
    } catch (error) {
        console.error('Failed to update OTA:', error);
        // Revert the switch if the update failed
        item.published = !item.published;
    }
};

const saveItem = async () => {
    if (!editedItem.value) {
        console.error('No item to save');
        return;
    }

    saving.value = true;
    try {
        let response;
        if (isEditing.value && editedItem.value._id) {
            response = await axios.put(
                `https://ota.safetelecom.net/api/updates/${editedItem.value._id}`,
                editedItem.value
            );
        } else {
            response = await axios.post(
                'https://ota.safetelecom.net/api/updates',
                editedItem.value
            );
        }

        if (response.data) {
            if (isEditing.value) {
                const index = updates.value.findIndex(item => item._id === editedItem.value._id);
                if (index !== -1) {
                    updates.value[index] = response.data;
                }
            } else {
                updates.value.push(response.data);
            }
        }

        closeDialog();
    } catch (error) {
        console.error('Save error:', error);
    } finally {
        saving.value = false;
    }
};

const confirmDelete = (items) => {
    itemsToDelete.value = items;
    deleteDialog.value = true;
};

const closeDelete = () => {
    deleteDialog.value = false;
    itemsToDelete.value = [];
};

const deleteItemsConfirm = async () => {
    deleting.value = true;
    try {
        await Promise.all(
            itemsToDelete.value.map(item =>
                axios.delete(`https://ota.safetelecom.net/api/updates/${item._id}`)
            )
        );
        // Remove deleted items from the selection and data
        selected.value = selected.value.filter(
            item => !itemsToDelete.value.includes(item)
        );
        updates.value = updates.value.filter(
            item => !itemsToDelete.value.some(deleteItem => deleteItem._id === item._id)
        );
        closeDelete();
    } catch (error) {
        console.error('Failed to delete items:', error);
    } finally {
        deleting.value = false;
    }
};

// Lifecycle
onMounted(() => {
    fetchUpdates();
});
</script>

<style scoped>
.v-data-table {
    width: 100%;
}

/* Dialog transitions */
.v-dialog-transition-enter-active,
.v-dialog-transition-leave-active {
    transition: all 0.3s ease-in-out;
}

.v-dialog-transition-enter-from,
.v-dialog-transition-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}

/* Table transitions */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* Alert transitions */
.alert-enter-active,
.alert-leave-active {
    transition: all 0.3s ease;
}

.alert-enter-from,
.alert-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}
</style>